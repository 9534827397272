<template>
  <div
    v-if="
      ($store.state.fastPermissoes.administrador == 'S' || $store.state.fastPermissoes.secretario == 'S') &&
        $store.state.fastPermissoes.ativo == 'S' &&
        $store.state.fastPlataformaModulos.modulo_secretaria
    "
  >
    <div class="fd-app-wrap">
      <div class="container">
        <!-- breadcrumb -->
        <nav>
          <ul class="fd-app-breadcrumb">
            <li class="fd-app-breadcrumb-item">
              <a
                href="/minhas-plataformas"
                class="aluno_font_color"
                @click.prevent="$router.push('/minhas-plataformas')"
              >Minhas Plataformas</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                href="#"
                class="aluno_font_color"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma)"
              >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/secretaria'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria')"
              >Modo secretaria</a>
            </li> 
            <li class="fd-app-breadcrumb-item active">
              <a
                class="aluno_font_color"
                href="#"
              >Dashboard</a>
            </li>
          </ul>
        </nav>
        <!-- /breadcrumb -->
      </div>

      <div class="fd-mode-adm-content mt-4">
        <div class="container">
          <div class="row">
            <div
              id="body_conteudo_modo_admin"
              class="col-sm-12 col-md-12 fd-mode-adm-content-body col-lg-12 ml-0"
            >
              <!-- fd-app-welcome -->
              <section class="fd-app-welcome text-left">
                <h1 class="aluno_font_color">
                  Dashboard 
                  <span id="txtDashboardNomePlataforma">da Secretaria</span>
                </h1>
                <img
                  :src="require('@/assets/images/form-effect-01.png')"
                  style="max-height: 35px"
                >
              </section>
              <!-- /fd-app-welcome -->
            </div>
          </div>
        </div>
        <div
          class="container-fluid"
          style="max-width: 1920px"
        >
          <div class="row">
            <div
              id="body_conteudo_modo_admin"
              class="col-sm-12 col-md-12 fd-mode-adm-content-body col-lg-12 ml-0"
            >
              <section class="p-4">
                <div class="row">   
                  <div class="col-sm-12 col-md-12 col-lg-4 p-4">
                    <div class="card border-left-primary shadow h-100">                    
                      <div class="card-body">
                        <div class="row no-gutters align-items-center">
                          <div class="col mr-2">
                            <div class="text-xs font-weight-bold text-secondary text-uppercase mb-1">
                              MATRÍCULAS ATIVAS
                            </div>
                            <div class="h1 mb-0 font-weight-bold text-success">
                              {{ fastMatriculasAtivas }}
                            </div>
                          </div>
                          <div class="col-auto">
                            <i class="fas fa-school fa-2x text-secondary" />
                            <b-icon-arrow-up-circle-fill
                              font-scale="2"
                              variant="secondary"
                            /> 
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-12 col-lg-4 p-4">
                    <div class="card border-left-primary shadow h-100">                    
                      <div class="card-body">
                        <div class="row no-gutters align-items-center">
                          <div class="col mr-2">
                            <div class="text-xs font-weight-bold text-secondary text-uppercase mb-1">
                              MATRÍCULAS PAGAS
                            </div>
                            <div class="h1 mb-0 font-weight-bold text-success">
                              {{ fastMatriculasPagas }}
                            </div>
                          </div>
                          <div class="col-auto">
                            <b-icon-credit-card-fill
                              font-scale="2"
                              variant="secondary"
                            /> 
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> 
                  <div class="col-sm-12 col-md-12 col-lg-4 p-4">
                    <div class="card border-left-primary shadow h-100">                    
                      <div class="card-body">
                        <div class="row no-gutters align-items-center">
                          <div class="col mr-2">
                            <div class="text-xs font-weight-bold text-secondary text-uppercase mb-1">
                              MATRÍCULAS INATIVAS
                            </div>
                            <div class="h1 mb-0 font-weight-bold text-danger">
                              {{ fastMatriculasInativas }}
                            </div>
                          </div>
                          <div class="col-auto">
                            <b-icon-arrow-down-circle-fill
                              font-scale="2"
                              variant="secondary"
                            /> 
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>  
                          
                  <div class="col-sm-12 col-md-12 col-lg-12 p-4">
                    <div class="card shadow">
                      <div class="card-header">
                        Matrículas por mês <span v-if="fastAnoAtual">no ano de {{ fastAnoAtual }} </span>
                      </div>
                      <div class="card-body">
                        <VueApexCharts
                          type="bar"
                          height="350"
                          :options="chartOptions1"
                          :series="series1"
                        />
                      </div>
                    </div>                    
                  </div>

                  <div class="col-12 p-4">
                    <div class="card shadow">
                        <div class="card-header">
                          Matrículas ativas e encerradas por curso
                        </div>
                        <div class="card-body">
                          <table class="table">
                            <thead>
                              <tr>
                                <th scope="col">
                                  Nome do Curso
                                </th>
                                <th scope="col">
                                  Sigla da Turma
                                </th>
                                <th scope="col">
                                  Ativas
                                </th>
                                <th scope="col">
                                  Encerradas
                                </th>
                              </tr>
                            </thead>
                            <tbody v-if="fastStatusMatriculas.loading">
                              <td colspan="6">
                                Carregando matrículas...
                              </td>
                            </tbody>
                            <tbody v-else-if="fastStatusMatriculas.length">
                              <tr v-for="status in fastStatusMatriculas" :key="status.sigla_turma">
                                <td>
                                  {{ status.nome_curso }}
                                </td>
                                <td>
                                  {{ status.sigla_turma }}
                                </td>
                                <td>
                                  {{ status.MatriculasAtivas }}
                                </td>
                                <td>
                                  {{ status.MatriculasEncerradas }}
                                </td>
                                </tr>
                                </tbody>
                                <tbody v-else>
                                  <td colspan="6" class="text-center">
                                Nenhuma matrícula encontrada...
                              </td>
                                </tbody>
                          </table>
                        </div>
                      </div>  
                  </div>

                  <div class="col-6 py-4 px-4">
                    <div class="card shadow">
                        <div class="card-header">
                          Mensalidades em aberto
                        </div>
                        <div class="card-body">
                          <table class="table">
                            <thead>
                              <tr>
                                <th scope="col">
                                  Nome do Curso
                                </th>
                                <th scope="col">
                                  Sigla da Turma
                                </th>
                                <th scope="col">
                                  Soma 
                                </th>
                              </tr>
                            </thead>
                            <tbody v-if="fastSomaMatriculas.loading">
                              <tr class="text-center">
                              <td colspan="6">
                                Carregando mensalidades...
                              </td>
                            </tr>
                            </tbody>
                            <tbody v-else-if="fastSomaMatriculas.length">
                              <tr v-for="soma in fastSomaMatriculas" :key="soma.sigla_turma">
                                <td>
                                  {{ soma.nome_curso }}
                                </td>
                                <td>
                                  {{ soma.sigla_turma }}
                                </td>
                                <td>
                                  R$ {{ formataPreco(soma.soma_mensalidades_ativas) }}
                                </td>
                              </tr>
                            </tbody>
                            <tbody v-else>
                              <td colspan="6" class="text-center">
                                Nenhuma mensalidade encontrada...
                              </td>
                            </tbody>
                          </table>
                        </div>
                      </div>  
                  </div>

                  <div class="col-6 py-4 px-4">
                    <div class="card shadow">
                        <div class="card-header">
                          Mensalidades em aberto com atraso maior que 30 dias
                        </div>
                        <div class="card-body">
                          <table class="table">
                            <thead>
                              <tr>
                                <th scope="col">
                                  Nome do Curso
                                </th>
                                <th scope="col">
                                  Sigla da Turma
                                </th>
                                <th scope="col">
                                  Soma 
                                </th>
                              </tr>
                            </thead>
                            <tbody v-if="fastSomaMatriculasAtrasadas.loading">
                            <tr class="text-center">
                              <td colspan="6">
                                Carregando mensalidades...
                              </td>
                            </tr>
                          </tbody>
                            <tbody v-else-if="fastSomaMatriculasAtrasadas.length">
                              <tr v-for="somaAtrasada in fastSomaMatriculasAtrasadas" :key="somaAtrasada.sigla_turma">
                                <td>
                                  {{ somaAtrasada.nome_curso }}
                                </td>
                                <td>
                                  {{ somaAtrasada.sigla_turma }}
                                </td>
                                <td>
                                  R$ {{ formataPreco(somaAtrasada.soma_mensalidades_ativas_atraso_30_dias)}}
                                </td>
                                </tr>
                                </tbody>
                                <tbody v-else>
                                  <td colspan="6" class="text-center">
                                Nenhuma mensalidade encontrada...
                              </td>
                                </tbody>
                          </table>
                        </div>
                      </div>  
                  </div>

                  <div class="col-12 p-4">
                    <div class="card shadow h-100">     
                      <div class="card-header">
                        Últimas matrículas
                      </div>               
                      <div class="card-body">
                        <table class="table">
                          <thead>
                            <tr>
                              <th scope="col">
                                Matrícula
                              </th>
                              <th scope="col">
                                Nome usuário
                              </th>
                              <th scope="col">
                                Data da matrícula
                              </th>
                              <th scope="col">
                                Ativa
                              </th>                              
                              <th scope="col">
                                Pago
                              </th>
                              <th scope="col">
                                Valor
                              </th>
                            </tr>
                          </thead>
                          <tbody v-if="fastMatriculas.loading">
                            <tr class="text-center">
                              <td colspan="6">
                                Carregando matrículas...
                              </td>
                            </tr>
                          </tbody>
                          <tbody v-else-if="fastMatriculas.pagination.length">
                            <tr
                              v-for="(e, index) in fastMatriculas.pagination"
                              :key="index"
                            >
                              <th>#{{ e.id_matricula }}</th>
                              <td>
                                <a
                                  :href="'/plataforma/' + $route.params.id_plataforma + '/secretaria/aluno/' + e.id_pessoa"
                                  target="_blank"
                                >
                                  {{ e.first_name }} {{ e.last_name }}
                                </a>
                              </td>
                              <td>
                                <span v-if="e.data_matricula">{{ formataDataT(e.data_matricula) }}</span>                                
                              </td>
                              <td>
                                <span
                                  v-if="e.matricula_ativa"
                                  class="text-success"
                                >Sim</span>
                                <span
                                  v-else
                                  class="text-danger"
                                >Não</span>
                              </td>
                              <td>
                                <span
                                  v-if="e.pago"
                                  class="text-success"
                                >Sim</span>
                                <span
                                  v-else
                                  class="text-danger"
                                >Não</span>
                              </td>
                              <td>
                                <span v-if="e.valor_matricula">R$ {{ formataPreco(e.valor_matricula) }}</span>
                                <span v-else>Gratuito</span>
                              </td>                             
                            </tr>                                              
                          </tbody>
                          <tbody v-else>
                            <tr class="text-center">
                              <td colspan="6">
                                Nenhuma matrícula realizada
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>   
                    <div class="col-12 text-center mt-4">
                      <Pagination
                        :page-size="20"
                        :items="fastMatriculas.filtro"
                        @changePage="fastMatriculas.pagination = $event"
                      />
                    </div>                 
                  </div>               
                </div>

                <div class="row mt-4 d-none">
                  <div class="col-sm-12 col-md-12 col-lg-12 p-4">
                    <div class="card shadow">
                      <div class="card-header">
                        Calendário de turmas
                      </div>
                      <div class="card-body">
                        <VueApexCharts
                          type="rangeBar"
                          height="350"
                          :options="chartOptions2"
                          :series="series2"
                        />
                      </div>
                    </div>                    
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="mt-5 mb-5">
            <a
              id="btn_voltar_modo_admin"
              class="btn btn-red-hollow"
              :href="'/plataforma/' + $route.params.id_plataforma + '/secretaria'"
              @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria')"
            >
              <b-icon-arrow-return-left />
              Voltar
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";
import VueApexCharts from "vue-apexcharts"; //https://github.com/apexcharts/vue-apexcharts https://apexcharts.com/vue-chart-demos
import Pagination from "../components/Pagination";
export default {
  name: "HomeInternoSecretariaDashboard",
  components: {
    VueApexCharts, Pagination
  },
  mixins: [methods],
  data: function () {
    return {
      // Matriculas
      fastMatriculas: {
        total: [],
        filtro: [],
        pagination: [],
        loading: true
      },
      //SomaMatriculas
      fastSomaMatriculas: {
        id_plataforma: 0,
        nome_curso: "",
        sigla_turma: "",
        soma_mensalidades_ativas: 0,
        loading: true
      },

      //SomaMatriculasAtrasadas
      fastSomaMatriculasAtrasadas:{
        id_plataforma: 0,
        nome_curso: "",
        sigla_turma: "",
        soma_mensalidades_ativas_atraso_30_dias: 0,
        loading: true
      },
      //StatusMatriculas
      fastStatusMatriculas: {
        id_plataforma: 0,
        nome_curso: "",
        sigla_turma: "",
        MatriculasEncerradas: 0,
        MatriculasAtivas: 0,
        loading: true
      },
      // Matrículas
      fastMatriculasAtivas: 0,
      fastMatriculasInativas: 0,   
      fastMatriculasPagas: 0,
      fastAnoAtual: 0,
      series1: [{
        name: "Matrículas",
        data: [0,0,0,0,0,0,0,0,0,0,0,0]
      }],
      chartOptions1: {
        annotations: {
          points: [{
            x: '',
            seriesIndex: 0,
            label: {
              borderColor: '#775DD0',
              offsetY: 0,
              style: {
                color: '#fff',
                background: '#775DD0',
              },
              text: 'Maior número',
            }
          }]
        },
        chart: {
          height: 350,
          type: 'bar',
        },
        plotOptions: {
          bar: {
            columnWidth: '50%',
            endingShape: 'rounded'  
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: 2
        },
        
        grid: {
          row: {
            colors: ['#fff', '#f2f2f2']
          }
        },
        xaxis: {
          labels: {
            rotate: -45
          },
          categories: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
          ],
          tickPlacement: 'on'
        },
        yaxis: {
          title: {
            text: 'Servings',
          },
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'light',
            type: "horizontal",
            shadeIntensity: 0.25,
            gradientToColors: undefined,
            inverseColors: true,
            opacityFrom: 0.85,
            opacityTo: 0.85,
            stops: [50, 0, 100]
          },
        }
      },
      // Turmas
      series2: [
            {
              data: [
                {
                  x: 'Turma 1',
                  y: [
                    new Date('2019-03-02').getTime(),
                    new Date('2019-03-04').getTime()
                  ]
                },
                {
                  x: 'Turma 2',
                  y: [
                    new Date('2019-03-04').getTime(),
                    new Date('2019-03-08').getTime()
                  ]
                },
                {
                  x: 'Turma 3',
                  y: [
                    new Date('2019-03-08').getTime(),
                    new Date('2019-03-12').getTime()
                  ]
                },
                {
                  x: 'Turma 4',
                  y: [
                    new Date('2019-03-12').getTime(),
                    new Date('2019-03-18').getTime()
                  ]
                }
              ]
            }
      ],
      chartOptions2: {
        chart: {
          height: 350,
          type: 'rangeBar'
        },
        plotOptions: {
          bar: {
            horizontal: true
          }
        },
        xaxis: {
          type: 'datetime'
        }
      },
    };
  },

  mounted: function () {
    // Captura ID da plataforma pela URL
    if (this.$route.params.id_plataforma) {
      this.getFastDependecias(this.$route.params.id_plataforma, 'secretario')
        .then(() => {
          if (this.$store.state.fastPlataformaModulos.modulo_secretaria) {
            this.getMatriculasPlataforma(this.$route.params.id_plataforma)
            this.getMatriculasSomaMensalidades(this.$route.params.id_plataforma)
            this.getMatriculasSomaMensalidadesAtrasadas(this.$route.params.id_plataforma)
            this.getMatriculaStatus(this.$route.params.id_plataforma)
            this.$store.state.fastCarregando = false;
          } else {
            this.$store.state.fastCarregando = false;
            this.exibeToasty("Módulo não disponível para sua plataforma", "error");
          }          
        })
        .catch((e) => {
          this.exibeToasty(e, "error");
          this.$store.state.fastCarregando = false;
        });
    }
  },
  methods: {
    async getMatriculasPlataforma(id_plataforma){
      this.promiseGetFastApi("api/fast_secretaria_matricula_aluno/lista_plataforma", `id_plataforma=${id_plataforma}`).then(obj => {
        //console.log("getMatriculasPlataforma", obj)
        if (obj.length) {
          this.fastMatriculas.total = obj
          this.fastMatriculas.filtro = obj
          
          this.fastAnoAtual = new Date().getFullYear()

          let alunosMes = {
            name: "Matrículas",
            data: [0,0,0,0,0,0,0,0,0,0,0,0]
          }
          obj.forEach(e => {
            // Contador de matrículas 
            if (e.matricula_ativa) this.fastMatriculasAtivas++
            if (!e.matricula_ativa) this.fastMatriculasInativas++
            if (e.pago) this.fastMatriculasPagas++
            // Matrículas por mês do ano atual
            if (e.data_matricula) {
              if (this.fastAnoAtual == new Date(e.data_matricula).getFullYear()) {                
                let mes = new Date(e.data_matricula).getMonth()
                alunosMes.data[mes] += 1   
              }           
            }
          })

          // Caputra máximo de matrículas
          var max = alunosMes.data.reduce(function(a, b) {
            return Math.max(a, b);
          });
          if (max) {
            this.chartOptions1.annotations.points = []
            // Coloca os destaques nos meses com maior número
            alunosMes.data.forEach((e, index) => {
              if (e == max) {                
                this.chartOptions1.annotations.points.push({
                  x: this.chartOptions1.xaxis.categories[index],
                  seriesIndex: 0,
                  label: {
                    borderColor: '#775DD0',
                    offsetY: 0,
                    style: {
                      color: '#fff',
                      background: '#775DD0',
                    },
                    text: 'Maior número',
                  }
                })               
              }
            })         
          }
          this.series1 = []
          this.series1.push(alunosMes)
        }
        else {
          this.fastMatriculas.total = []
          this.fastMatriculas.total = []
          this.chartOptions1.annotations.points = []
        }          
        this.fastMatriculas.loading = false  
      }).catch(e => {         
        this.exibeToasty("Erro ao buscar matrículas", "error");
        this.fastMatriculas.loading = false 
        console.log(e) 
      })
    },
    async getMatriculaStatus(id_plataforma){
     // this.fastStatusMatriculas.loading = true
      this.promiseGetFastApi("api/fast_tesouraria_invoice_parcela/lista_matriculas_status", `IdPlataforma=${id_plataforma}`).then(obj => {
        if(obj){
          this.fastStatusMatriculas = obj
          console.log(obj)
        }
                
        this.fastStatusMatriculas.loading = false  
      }).catch(e => {         
        this.exibeToasty("Erro ao buscar matrículas", "error");
        this.fastStatusMatriculas.loading = false
        console.log(e)  
      })
    },
    //somaMatriculasMensalidades
    async getMatriculasSomaMensalidades(id_plataforma){
     // this.fastSomaMatriculas.loading = true
      this.promiseGetFastApi("api/fast_tesouraria_invoice_parcela/lista_soma_mensalidades", `IdPlataforma=${id_plataforma}`).then(obj => {
        if(obj){
          this.fastSomaMatriculas = obj
          console.log(obj)
        }
                
        this.fastSomaMatriculas.loading = false  
      }).catch(e => {         
        this.exibeToasty("Erro ao buscar matrículas", "error");
        this.fastSomaMatriculas.loading = false
        console.log(e)  
      })
    },
    async getMatriculasSomaMensalidadesAtrasadas(id_plataforma){
   //   this.fastSomaMatriculasAtrasadas.loading = true
      this.promiseGetFastApi("api/fast_tesouraria_invoice_parcela/lista_soma_mensalidades_atrasadas", `IdPlataforma=${id_plataforma}`).then(obj => {
        if(obj){
          this.fastSomaMatriculasAtrasadas = obj
          console.log(obj)
        }
                
        this.fastSomaMatriculasAtrasadas.loading = false  
      }).catch(e => {         
        this.exibeToasty("Erro ao buscar matrículas", "error");
        this.fastSomaMatriculasAtrasadas.loading = false
        console.log(e)  
      })
    }

  },

};
</script>

<style></style>
